body {
  min-width: 100vw;
  min-height: 100vh;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0;
}
